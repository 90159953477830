import styled from 'styled-components';

export const StyledSectionHeading = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  position: relative;
  padding-bottom: 1rem;

  &:after {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    content: ' ';
    width: 10rem;
    border-bottom: 4px solid var(--main);
  }
`;
