import styled from 'styled-components';
import LocationIcon from '../../svg/location.svg';
import EmailIcon from '../../svg/mail.svg';
import TelephoneIcon from '../../svg/phone.svg';
import FacebookIcon from '../../svg/facebook.svg';
import InstagramIcon from '../../svg/instagram.svg';

export const ContactSection = styled.section`
  padding: 8rem 0;
  background-color: var(--lightGrey);
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
`;
export const IconTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  width: 25rem;

  svg {
    width: 4rem;
    cursor: pointer;
    height: 4rem;
    fill: var(--main);
    transition: fill 0.25s ease, transform 0.25s ease;

    &:hover {
      fill: var(--darkYellow);
      transform: translateY(-0.1rem) scale(1.02);
    }

    @media ${({ theme }) => theme.mediaQueries.smaller} {
      width: 3.3rem;
      height: 3.3rem;
    }
  }
`;
export const Title = styled.h5`
  font-size: 1.7rem;
  font-weight: 400;
  margin-left: 2rem;

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    font-size: 1.5rem;
  }
`;

export const StyledExternalLink = styled.a`
  &:last-child {
    margin-top: 1rem;
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
`;
export const SubHeading = styled.h6`
  font-size: 1.7rem;
  margin-bottom: 2rem;
  font-weight: 700;

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    font-size: 1.5rem;
  }
`;

export const Location = styled(LocationIcon)``;
export const Email = styled(EmailIcon)``;
export const Telephone = styled(TelephoneIcon)``;

export const Facebook = styled(FacebookIcon)`
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
  transition: fill 0.25s ease, transform 0.25s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-0.1rem) scale(1.02);
  }

  @media ${({ theme }) => theme.mediaQueries.smaller} {
    width: 3.3rem;
    height: 3.3rem;
  }
`;
export const Instagram = styled(Facebook).attrs({
  as: InstagramIcon,
})`
  margin-top: 2rem;
`;
