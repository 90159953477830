import React from 'react';
import PropTypes from 'prop-types';

import { StyledImage } from './GalleryItem.styles';

const GalleryItem = ({ image }) => {
  return <StyledImage fluid={image} />;
};

GalleryItem.propTypes = {};

export default GalleryItem;
