import React from 'react';
import PropTypes from 'prop-types';

//Components
import SectionHeading from '../../components/section-heading/SectionHeading';

//Styles
import {
  ContactSection,
  ContentContainer,
  IconTitle,
  SubHeading,
  Title,
  StyledExternalLink,
  SocialLinks,
  Location,
  Facebook,
  Instagram,
  Email,
  Telephone,
} from './Contact.styles';

const Contact = () => {
  return (
    <ContactSection id="kontakt">
      <SectionHeading>Kontakt</SectionHeading>
      <ContentContainer>
        <IconTitle>
          <StyledExternalLink
            href="https://www.google.com/maps/place/Strasz%C4%99cin,+Poland/@50.0633668,21.3443235,14z/data=!3m1!4b1!4m5!3m4!1s0x473d0af89aba567b:0x19f3179298577efc!8m2!3d50.0634793!4d21.3644742"
            target="_blank"
            rel="noopener"
          >
            <Location />
          </StyledExternalLink>
          <Title>39-218, Straszęcin 437</Title>
        </IconTitle>
        <IconTitle>
          <StyledExternalLink
            href="mailto:Skrzypek-dominik@wp.pl"
            rel="noopener"
          >
            <Email />
          </StyledExternalLink>
          <Title>Skrzypek-dominik@wp.pl</Title>
        </IconTitle>
        <IconTitle>
          <StyledExternalLink href="tel:+48 730 633 756" rel="noopener">
            <Telephone />
          </StyledExternalLink>
          <Title>+48 730 633 756</Title>
        </IconTitle>
        <SocialLinks>
          <SubHeading>Lub Media Społecznościowe</SubHeading>
          <StyledExternalLink
            href="https://www.facebook.com/Firma-Us%C5%82ugowo-Budowlana-DOMIN-100445691499134"
            target="_blank"
            rel="noopener"
          >
            <Facebook />
          </StyledExternalLink>
          <Title>Firma Usługowo-Budowlana DOMIN</Title>
          <StyledExternalLink
            href="https://instagram.com/dominogrodzenia?igshid=hal3su0bt56y"
            target="_blank"
            rel="noopener"
          >
            <Instagram />
          </StyledExternalLink>
          <Title>@dominogrodzenia</Title>
        </SocialLinks>
      </ContentContainer>
    </ContactSection>
  );
};

Contact.propTypes = {};

export default Contact;
