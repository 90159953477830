import React from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

//Custom Swiper styles
import './SwiperPanelCustomised.scss';

const SwiperPanel = ({ items, slidesPerView }) => {
  const renderSlides = data => {
    return data.map(item => (
      <SwiperSlide key={nextId('slide-')}>{item}</SwiperSlide>
    ));
  };
  return (
    <Swiper
      spaceBetween={5}
      slidesPerView={slidesPerView}
      loop
      navigation
      pagination={{
        clickable: true,
      }}
    >
      {items && renderSlides(items)}
    </Swiper>
  );
};

SwiperPanel.propTypes = {
  slidesPerView: PropTypes.number,
};

SwiperPanel.defaultProps = {
  slidesPerView: 5,
};

export default SwiperPanel;
