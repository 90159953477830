import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

//Styles
import { OffersSection, OffersContainer } from './Offers.styles';

//Components
import SectionHeading from '../../components/section-heading/SectionHeading';
import DescriptionCard from '../../components/description-card/DescriptionCard';
import Modal from '../../components/modal/Modal';

const Offers = () => {
  const [showModal, setShowModal] = useState(false);
  const [cardData, setCardData] = useState([]);

  const { allFile: items } = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "content" }
          extension: { eq: "md" }
        }
        sort: { fields: childMarkdownRemark___frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                title
                description
                moreInformation
                image {
                  childImageSharp {
                    fluid(maxWidth: 400, maxHeight: 240, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                modalImage {
                  childImageSharp {
                    fluid(maxHeight: 405, quality: 85) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                features
              }
            }
          }
        }
      }
    }
  `);

  const onMoreInformationClick = (
    title,
    description,
    image,
    modalImage,
    moreInformation,
    features
  ) => {
    setCardData({
      title,
      description,
      moreInformation,
      image,
      modalImage,
      features,
    });
    setShowModal(prev => !prev);
  };

  return (
    <OffersSection id="co-oferujemy">
      <SectionHeading>Co Oferujemy</SectionHeading>
      <OffersContainer>
        {items.edges.map(item => (
          <DescriptionCard
            key={item.node.id}
            data={item.node.childMarkdownRemark}
            onMoreInformationClick={onMoreInformationClick}
          />
        ))}
        <Modal
          cardData={cardData}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      </OffersContainer>
    </OffersSection>
  );
};

Offers.propTypes = {};

export default Offers;
