import React from 'react';
import PropTypes from 'prop-types';

import { StyledSectionHeading } from './SectionHeading.styles';
const SectionHeading = ({ children }) => {
  return <StyledSectionHeading>{children}</StyledSectionHeading>;
};

SectionHeading.propTypes = {
  children: PropTypes.string,
};

SectionHeading.defaultProps = {
  children: '',
};

export default SectionHeading;
