import styled from 'styled-components';
import Img from 'gatsby-image';
import CrossIcon from '../../svg/cross.svg';
import { animated } from 'react-spring';

export const AnimatedContainer = styled(animated.div)`
  position: fixed;
  z-index: 10;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  opacity: 0.2;
  background-color: black;
`;
export const ModalWrapper = styled(animated.div)`
  width: 70rem;
  height: 40.5rem;
  background: var(--white);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  z-index: 15;
  border-radius: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  @media ${({ theme }) => theme.mediaQueries.medium} {
    width: 65rem;
    height: 45rem;
    top: 55%;
  }

  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    grid-template-columns: 1fr;
    grid-template-rows: 16rem calc(100% - 16rem);

    width: 55rem;
    height: 50rem;

    @media (max-width: 25em) and (max-height: 630px) {
      grid-template-rows: 10rem calc(100% - 10rem);
    }
  }

  @media ${({ theme }) => theme.mediaQueries.small} {
    width: 45rem;
  }
  @media ${({ theme }) => theme.mediaQueries.smaller} {
    width: 35rem;
    height: 55rem;
  }
  @media ${({ theme }) => theme.mediaQueries.smallest} {
    width: 31rem;
    height: 55rem;
  }
  @media (max-width: 25em) and (max-height: 630px) {
    height: calc(100vh - 5.5rem);
    width: 100vw;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5rem 2rem;

  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    padding: 2rem;
  }
`;

export const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;

  @media (max-width: 25em) and (max-height: 630px) {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
`;
export const Description = styled.p`
  font-size: 1.3rem;
  margin-bottom: 2rem;

  @media (max-width: 25em) and (max-height: 630px) {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
`;

export const FeaturesSection = styled.div`
  width: 100%;
  border-top: 1px solid var(--lightGrey);
  border-bottom: 1px solid var(--lightGrey);
  padding: 1rem 0;

  @media (max-width: 25em) and (max-height: 630px) {
    padding: 0.5rem 0;
  }
`;

export const AnimatedFeaturesContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  animation: slideIn 0.5s ease-in-out;
  width: 100%;

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(50%);
    }
    to {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`;
export const ModalImage = styled(Img)`
  width: 100%;
  height: 100%;
  border-radius: 1rem 0 0 1rem;
  background: var(--white);

  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    border-radius: 0;
  }
`;

export const CloseModalButton = styled(CrossIcon)`
  cursor: pointer;
  position: absolute;
  display: block;
  top: 1rem;
  right: 1rem;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  z-index: 10;
  transition: transform 0.25s ease;
  fill: var(--secondary);
  &:hover {
    transform: rotate(90deg);
  }

  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    background: var(--white);
    border-radius: 10rem;
    right: 2rem;
    top: 2rem;
  }
`;
