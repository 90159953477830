import styled from 'styled-components';
import Img from 'gatsby-image';

export const AboutSection = styled.section`
  clip-path: polygon(0% 10%, 100% 0, 100% 100%, 0 100%);
  background: var(--lightGrey);
  display: flex;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.medium} {
    clip-path: polygon(0% 7%, 100% 0, 100% 100%, 0 100%);
  }

  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    clip-path: polygon(0% 3%, 100% 0, 100% 100%, 0 100%);
    flex-direction: column;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 50%;
  padding-top: 6rem;

  > div {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @media ${({ theme }) => theme.mediaQueries.medium} {
    padding-top: 3rem;
  }
`;

export const CenteredContainer = styled.div`
  max-width: 69rem;
  z-index: 2;
  padding: 4rem;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    padding: 3rem 3rem 1rem 3rem;
  }
`;
export const Description = styled.p`
  font-size: 1.8rem;
  margin-top: 3rem;

  @media ${({ theme }) => theme.mediaQueries.medium} {
    font-size: 1.6rem;
  }
`;
export const RightSide = styled.div`
  position: relative;
  flex: 1 1 50%;
  width: 100%;
  z-index: 10;
  border-bottom-left-radius: 3% 50%;
  border-top-left-radius: 2% 50%;

  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    border-bottom-left-radius: 0% 0%;
    border-top-left-radius: 0% 0%;
  }
`;
export const StyledImage = styled(Img)`
  opacity: 1;
  width: 100%;
  z-index: 2;
  height: 100%;
  border-bottom-left-radius: 3% 50%;
  border-top-left-radius: 2% 50%;

  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    border-bottom-left-radius: 0% 0%;
    border-top-left-radius: 0% 0%;
  }
`;

export const Quote = styled.p`
  font-size: 2rem;
  padding: 3rem 0;

  @media ${({ theme }) => theme.mediaQueries.medium} {
    font-size: 1.8rem;
  }
`;
