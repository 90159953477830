import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

//Styles
import { GallerySection, ContentContainer } from './Gallery.styles';

//Components
import GalleryItem from '../../components/gallery-item/GalleryItem';
import SwiperPanel from '../../components/swiper-panel/SwiperPanel';
import SectionHeading from '../../components/section-heading/SectionHeading';

const Gallery = () => {
  const [slidesPerView, setSlidesPerView] = useState(3);
  const changeMobile = () => {
    if (window.matchMedia('(max-width: 56.25em)').matches) {
      setSlidesPerView(2);
    } else {
      setSlidesPerView(3);
    }
  };

  useEffect(() => {
    changeMobile();
    window.addEventListener('resize', changeMobile);
    return () => window.removeEventListener('resize', changeMobile);
  });

  const { allContentfulGalleryImage } = useStaticQuery(graphql`
    query {
      allContentfulGalleryImage {
        nodes {
          firstImage {
            fluid(maxWidth: 750, maxHeight: 750, quality: 85) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  `);

  const renderContent = data => {
    return data.map(item => {
      const { fluid } = item.firstImage;
      return <GalleryItem image={fluid} />;
    });
  };

  const { nodes } = allContentfulGalleryImage;
  return (
    <GallerySection id="galeria">
      <SectionHeading>Galeria</SectionHeading>
      <ContentContainer>
        <SwiperPanel
          slidesPerView={slidesPerView}
          items={nodes.length > 0 && renderContent(nodes)}
        />
      </ContentContainer>
    </GallerySection>
  );
};

Gallery.propTypes = {};

export default Gallery;
