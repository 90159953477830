import styled from 'styled-components';
import Img from 'gatsby-image';

export const CardContainer = styled.div`
  display: grid;
  background-color: var(--veryLightGrey);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 17rem auto 5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
`;
export const Image = styled(Img)`
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  display: block !important;
  border-radius: 0px;
`;
export const TextContainer = styled.div``;
export const Title = styled.h4`
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  width: 80%;
  justify-self: center;
  align-self: end;
  font-size: 1.6rem;
  text-align: center;
  padding: 1.25rem;
  background-color: var(--darkNavyBlue);
  color: #fff;
  transform: translateY(50%);
`;
export const Description = styled.p`
  margin-top: 2.5rem;
  font-size: 1.6rem;
  grid-column: 1 / -1;
  padding: 2rem;
`;

export const MoreInformationButton = styled.button`
  grid-column: 1 / -1;
  padding: 1.8rem 3rem;
  align-self: flex-end;
  cursor: pointer;
  border: none;
  font-size: 1.4rem;
  background-color: #f3bb53;
  outline: none;
  display: flex;
  justify-content: center;

  &:hover > svg {
    transform: translateX(0.3rem);
    margin-left: 0.7rem;
  }

  & > svg {
    transition: margin-left 0.25s ease, transform 0.25s ease;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
`;
