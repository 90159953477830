import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

//Styles
import {
  AboutSection,
  LeftSide,
  CenteredContainer,
  Description,
  RightSide,
  Quote,
  StyledImage,
} from './About.styles';

//Components
import { Container } from '../../components/common/elements';
import SectionHeading from '../../components/section-heading/SectionHeading';

const About = () => {
  const { file } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "aboutBanner.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxHeight: 1080) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return (
    <AboutSection id="dlaczego-my?">
      <LeftSide>
        <CenteredContainer>
          <SectionHeading>Dlaczego my? / O Nas</SectionHeading>
          <Description>
            Jesteśmy cieszącą się uznaniem firmą o wieloletniej tradycji i
            doświadczeniu. Wykonujemy szeroki wachlarz usług zapewniając naszym
            klientom spokój i pewność wykonanej pracy.
          </Description>
          <Description>
            Jeśli poszukujesz wysokiej klasy fachowców to zapraszamy do
            zapoznania się z naszą ofertą, przejrzyste menu pozwoli poznać
            oferowane przez nas usługi, oraz zobaczyć wykonane przez nas
            zlecenia.
          </Description>
          <Quote>Pozdrawiamy Serdecznie i zapraszamy do współpracy</Quote>
        </CenteredContainer>
      </LeftSide>
      <RightSide>
        <StyledImage fluid={file.childImageSharp.fluid} />
      </RightSide>
    </AboutSection>
  );
};

About.propTypes = {};

export default About;
