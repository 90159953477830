import React from 'react';
import { Link } from 'gatsby';

//Components
import Layout from '../components/layout/Layout';
import SEO from '../components/seo/SEO';

//Sections
import Header from '../sections/header/Header';
import Offers from '../sections/offers/Offers';
import About from '../sections/about/About';
import Gallery from '../sections/gallery/Gallery';
import Contact from '../sections/contact/Contact';

const IndexPage = () => (
  <Layout>
    <SEO />
    <Header />
    <Offers />
    <About />
    <Gallery />
    <Contact />
  </Layout>
);

export default IndexPage;
