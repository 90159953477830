import React, { useRef, useEffect, useCallBack } from 'react';
import { useTransition, animated, useSpring } from 'react-spring';
import PropTypes from 'prop-types';

import {
  AnimatedContainer,
  ModalWrapper,
  ModalImage,
  ModalContent,
  Title,
  Description,
  FeaturesSection,
  AnimatedFeaturesContent,
  CloseModalButton,
} from './Modal.styles';
import FeatureItem from '../feature-item/FeatureItem';

const Modal = ({ cardData, showModal, setShowModal }) => {
  const props = useTransition(showModal, null, {
    from: { opacity: 0, marginTop: '-50px' },
    enter: { opacity: 1, marginTop: '0px' },
    leave: { opacity: 0, marginTop: '50px' },
  });

  const propsWrapper = useTransition(showModal, null, {
    from: { opacity: 0 },
    enter: { opacity: 0.6 },
    leave: { opacity: 0 },
  });

  const {
    title,
    description,
    image,
    modalImage,
    moreInformation,
    features,
  } = cardData;

  console.log(modalImage);

  return (
    <>
      {propsWrapper.map(({ item, props, key }) => {
        return item ? (
          <AnimatedContainer
            key={key}
            style={props}
            onClick={() => setShowModal(false)}
          />
        ) : null;
      })}
      {props.map(({ item, key, props }) => {
        return item ? (
          <ModalWrapper
            onClick={e => e.stopPropagation()}
            style={props}
            key={key}
          >
            <ModalImage fluid={modalImage.childImageSharp.fluid} />
            <ModalContent>
              <Title>{title}</Title>
              <Description>{moreInformation}</Description>
              <FeaturesSection>
                <AnimatedFeaturesContent>
                  {features.map(featureTitle => (
                    <FeatureItem
                      featureTitle={featureTitle}
                      key={featureTitle}
                    />
                  ))}
                </AnimatedFeaturesContent>
              </FeaturesSection>
            </ModalContent>
            <CloseModalButton
              aria-label="Close Modal"
              onClick={() => setShowModal(false)}
            />
          </ModalWrapper>
        ) : null;
      })}
    </>
  );
};

Modal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

Modal.defaultProps = {
  showModal: false,
  setShowModal: () => {},
};

export default Modal;
