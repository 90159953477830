import React from 'react';
import PropTypes from 'prop-types';

import {
  CardContainer,
  Image,
  TextContainer,
  Title,
  Description,
  MoreInformationButton,
} from './DescriptionCard.styles';

import ArrowRight from '../../svg/arrow-right2.svg';

const DescriptionCard = ({ data, onMoreInformationClick }) => {
  const {
    title,
    description,
    image,
    modalImage,
    moreInformation,
    features,
  } = data.frontmatter;

  return (
    <CardContainer>
      <Image fluid={image.childImageSharp.fluid} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <MoreInformationButton
        onClick={() =>
          onMoreInformationClick(
            title,
            description,
            image,
            modalImage,
            moreInformation,
            features
          )
        }
      >
        Więcej Informacji
        <ArrowRight />
      </MoreInformationButton>
    </CardContainer>
  );
};

DescriptionCard.propTypes = {};

export default DescriptionCard;
