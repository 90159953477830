import styled from 'styled-components';

export const OffersSection = styled.section`
  padding: 4rem 3rem;
  max-width: 140rem;
  z-index: 2;
  margin: 0 auto;
  width: 100%;

  @media ${({ theme }) => theme.mediaQueries.small} {
    padding: 0 2rem;
  }
`;

export const OffersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 31rem));
  justify-content: center;
  gap: 3rem;
  padding: 4rem 0;
  position: relative;
`;
