import styled from 'styled-components';

export const GallerySection = styled.section`
  padding: 6rem 0;

  @media ${({ theme }) => theme.mediaQueries.medium} {
    padding: 6rem 0 3rem 0;
  }
  @media ${({ theme }) => theme.mediaQueries.smallMedium} {
    padding: 3rem 0;
  }
`;

export const ContentContainer = styled.div`
  padding: 6rem 0;

  @media ${({ theme }) => theme.mediaQueries.medium} {
    padding: 6rem 0 3rem 0;

    @media ${({ theme }) => theme.mediaQueries.smallMedium} {
      padding: 3rem 0;
    }
  }
`;
