import React from 'react';
import PropTypes from 'prop-types';

import { FeatureItemContainer, ArrowIcon, Title } from './FeatureItem.styles';

const FeatureItem = ({ featureTitle }) => {
  return (
    <FeatureItemContainer>
      <ArrowIcon />
      <Title>{featureTitle}</Title>
    </FeatureItemContainer>
  );
};

FeatureItem.propTypes = {
  featureTitle: PropTypes.string,
};

FeatureItem.defaultProps = {
  featureTitle: '',
};

export default FeatureItem;
