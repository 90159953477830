import styled from 'styled-components';
import ChevronRight from '../../svg/chevron-right.svg';

export const FeatureItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1.3rem 0;
`;
export const ArrowIcon = styled(ChevronRight)`
  fill: var(--main);
`;
export const Title = styled.h4`
  font-size: 1.3rem;
  margin-left: 0.5rem;
  font-weight: normal;
`;
